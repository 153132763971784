<template>
  <b-card body-class="p-0" header-class="border-0 transactions">
    <template v-slot:header>
      <b-row align-v="center" class="filter-inputs">
        <b-col sm="12" lg="2">
          <h3 class="mb-0">Total ({{ transactionsTotal }})</h3>
        </b-col>

        <b-col sm="12" lg="7" class="text-right col-center filter-selects">
          <b-row>
            <b-col sm="12" lg="4" class="data-list">
              <!-- Search input -->
              <b-form-input
                v-model="filter"
                placeholder="Filter transactions"
                class="filter-input"
                v-bind:class="{ active: isActive, focused: isFocused }"
                @keyup="selectedDistributor"
                @focus="focused"
              >
              </b-form-input>
            </b-col>
            <b-col sm="12" lg="4">
              <el-date-picker
                v-model="formattedFromDate"
                type="datetime"
                @change="filterDates($event)"
                placeholder="from"
                class="input-center"
              >
              </el-date-picker>
            </b-col>
            <b-col sm="12" lg="4">
              <el-date-picker
                v-model="formattedToDate"
                type="datetime"
                @change="filterDates($event)"
                placeholder="until"
              >
              </el-date-picker>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" lg="3" class="text-right report-buttons">
          <a href="#!" class="btn btn-sm btn-primary" @click="reload()"
            >Reload</a
          >
          <a href="#!" class="btn btn-sm btn-primary" @click="getCSV()"
            >Export CSV</a
          >
          <a href="#!" class="btn btn-sm btn-primary" @click="getReport()"
            >Report</a
          >
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>
    <el-table
      class="table-responsive table transactions"
      :data="displayData"
      header-row-class-name="thead-light"
      empty-text="No transactions found"
      v-if="!isLoading"
      @sort-change="sortChange"
      style="width: 100% !important"
    >
      <el-table-column
        label="Txn number"
        :formatter="txnFormatter"
        min-width="70px"
        max-width="60px"
        prop="Id"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Tavern / Client"
        min-width="90px"
        prop="clientName"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Distributor"
        min-width="70px"
        prop="Name"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Date/Time"
        min-width="70px"
        :formatter="dateFormatter"
        prop="DateCreated"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Amount"
        min-width="70px"
        :fields="fields"
        :formatter="currencyFormatter"
        prop="Amount"
        sortable
      >
      </el-table-column>
      <el-table-column label="Type"
                       min-width="45px"
                       prop="Type" sortable>
      </el-table-column>
      <el-table-column label="" min-width="35px">
        <template v-slot="{ row }">
          <button
            class="btn btn-sm btn-primary"
            :data-id="row.Id"
            @click="showModal(row)"
          >
            <span class="button-text">View</span>
              <span class="button-icon"><i class="far fa-eye"></i></span>
          </button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      :total="rows"
      :page-size="pageSize"
      align="right"
      v-if="!isLoading"
    >
    </el-pagination>
    <modal :show="isModalVisible" @close="closeModal">
      <div class="form">
        <p style="font-weight: 700">
          Transaction details
          <span style="color: #f7941d;">{{ padNumber(this.selectedId) }}</span>
          ({{ this.selectedType }})
        </p>
        <!-- display transaction details here -->
        <div >
          <div >
            <b-row align-v="center">
              <b-col cols="12"><br /></b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>TxnNumber</strong></b-col>
              <b-col cols="8" style="text-align:right;">{{
                padNumber(this.selectedId)
              }}</b-col>
            </b-row>
            <b-row align-v="center" v-if="!isSupplier">
              <b-col cols="4"><strong>Type</strong></b-col>
              <b-col cols="8" style="text-align:right;">{{
                this.selectedType
              }}</b-col>
            </b-row>
            <b-row align-v="center" v-if="!isSupplier">
              <b-col cols="4"><strong>Client</strong></b-col>
              <b-col cols="8" style="text-align:right;">{{
                this.selectedClient
              }}</b-col>
            </b-row>
            <b-row align-v="center" v-if="!isSupplier">
              <b-col cols="4"><strong>Supplier Name</strong></b-col>
              <b-col cols="8" style="text-align:right;">{{
                this.selectedSupplier
              }}</b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Amount</strong></b-col>
              <b-col cols="8" style="text-align:right;">{{
                formatCurrency(this.selectedAmount)
              }}</b-col>
            </b-row>
          </div>
          <br />
          <b-button @click="closeModalHandler" type="submit" variant="primary"
            >OK</b-button
          >
        </div>
      </div>
    </modal>
  </b-card>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DatePicker,
  TimePicker,
  Pagination,
  Select,
  Option
} from "element-ui";
import config from "../../config";
import { helpers } from "../../helpers";
import Spinner from "../../components/Spinner.vue";
import modal from "../../components/Modal.vue";
import { DateTime } from "luxon";

export default {
  name: "transactions-table",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner
  },
  methods: {
      sortChange($event){
        // Get sort change data
        const { prop, order } = $event;
        console.log($event);
        // Set sort direction order 
        this.sortDirection = order == "ascending" ? "asc" : "desc";
        // Set sort filter type 
        this.sort = prop;
        // Check for sort order and table data accordingly
        if(order == "ascending") {
          if(prop == "clientName") {
            this.tableData = this.tableData.sort((a, b) => ((a.clientName > b.clientName) ? 1 : -1));
          }
          this.tableData = this.tableData.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
        } else {
          if(prop == "clientName") {
            this.tableData = this.tableData.sort((a, b) => ((a.clientName < b.clientName) ? 1 : -1));
          }
          this.tableData = this.tableData.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
        }

        console.log('Table data:', this.tableData);
      },
      showModal(item) {
        console.log(item);
        this.selectedName = item.name;
        this.selectedId = item.Id;
        this.selectedType = item.Type;
        this.selectedAmount = item.Amount;
        this.selectedSupplier = item.Name;
        this.selectedClient = item.clientName;
        this.isModalVisible = true;
        this.getDetails(item.Id);
      },
      closeModalHandler() {
        this.isModalVisible = false;
        this.selectedName = '';
        this.selectedId = '';
        this.selectedType = '';
        this.selectedSupplier = '';
        this.selectedClient = '';
        this.selectedAmount = 0;
      },
      closeModal() {
        this.isModalVisible = false;
      },
      padNumber(number) {
        if (number.toString().length < 8) {
          for (var i = number.toString().length; i <= 10; i++) {
            number = "0" + number;
          }
          return number;
        } else {
          return number;
        }
      },
     handleCurrentChange(val) {
      this.currentPage = val;
    },
    currencyFormatter(row, column, cellValue) {
      return this.formatCurrency(cellValue);
    },
    txnFormatter(row, column, cellValue) {
      return helpers.padNumber(cellValue);
    },
    dateFormatter(row, column, cellValue) {
      return this.formatDateISO(cellValue);
    },
    formatDate(cellValue) {
      var date = new Date(cellValue);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    formatDateISO(inputDate) {
      var date = new Date(inputDate);
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    formatCurrency(cellValue) {
      return parseFloat(cellValue).toLocaleString("en-ZA", {
        style: "currency",
        currency: "ZAR"
      });
    },
    percentageFormatter(row, column, cellValue) {
      return this.formatPercentage(cellValue);
    },
    formatPercentage(row, column, cellValue) {
      return parseFloat(cellValue).toFixed(2) + " %";
    },
    changeSupplier(selectedValue) {
      this.supplier = selectedValue;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });
      http
        .post("/transaction/all", {
          fromDate: this.fromDate,
          toDate: this.toDate,
          filter: this.filter,
          sort: this.sort,
          sortOrder: this.sortDirection
        })
        .then(response => {
          if (response.data.length > 0) {
            this.tableData = response.data;

            this.handleCurrentChange(1);
          } else {
            this.tableData = [];
            this.handleCurrentChange(1);
          }
        })
        .catch(err => {
          this.tableData = [];
          this.handleCurrentChange(1);
        });
    },
    filterDates(event) {
      this.isLoading = true;

      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });
      http
        .post("/transaction/all", {
          fromDate: this.fromDate,
          toDate: this.toDate,
          sort: this.sort,
          sortOrder: this.sortDirection,
          userId: this.loggedinUserId
        })
        .then(response => {
          if (response.data.length > 0) {
            this.tableData = response.data;
            this.masterData = response.data;
            this.displayFilteredTableData()
            this.handleCurrentChange(1);
            this.isLoading = false;
            // Check if filter input has a value

            this.formatTransactionsData(this.tableData);
            // Enable filter distributors input
            this.disabled = false;
          } else {
            this.tableData = [];
            this.masterData = [];
            // Enable filter distributors input
            this.disabled = true;
            this.handleCurrentChange(1);
            this.isLoading = false;
          }
        })
        .catch(err => {
          this.tableData = [];
          this.masterData = [];
          // Enable filter distributors input
          this.disabled = true;
          this.handleCurrentChange(1);
          this.isLoading = false;
        });
    },
    displayFilteredTableData() {
      this.tableData = this.masterData.filter(s => s.supplierName.includes(this.filter) ||
              s.clientName.toLowerCase().includes(this.filter) ||
              s.Name.toLowerCase().includes(this.filter) ||
              s.Type.toLowerCase().includes(this.filter))
    },
    getDetails(Id) {
      this.isDetailLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });
      console.log(Id);
      http
        .get("/transaction/" + Id)
        .then(response => {
          if (response.data) {
            this.transactionDetail = response.data;
            this.isDetailLoading = false;
          } else {
            this.transactionDetail = [];
            this.isDetailLoading = false;
          }
        })
        .catch(err => {
          this.transactionDetail = [];
          this.isDetailLoading = false;
        });
    },
    onFilterHandler() {
      // 1. Get fromDate (this.fromDate) & toDate (this.toDate)
      // 2. Format date to match server requirements
      // 3. Do async call here
      // 4. Populate table with response data
    },
    reload() {
      const query = Object.assign({}, this.$route.query);
      for(var obj in query){
        delete query[obj];
      }
      this.$router.replace({ query });
      this.$router.go();
    },
    getCSV() {
      this.isLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 60000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });

      const report = {
        fromDate: this.formattedFromDate,
        toDate: this.formattedToDate,
        filter: this.filter,
        sort: this.sort,
        sortOrder: this.sortDirection,
        supplierId: '',
        userId: this.loggedinUserId
      };

      http
        .post("/transaction/csv", report, {
          responseType: "blob"
        })
        .then(response => {
          console.log(response);
          var newBlob = new Blob([response.data], {
            type: "application/octet-stream"
          });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              newBlob,
              "transactions-report.csv"
            );
            return;
          }
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.style = "display: none;";
          link.href = data;
          link.download = "transaction-report.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.isLoading = false;
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            this.isLoading = false;
          }, 100);
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    getReport() {
      //this.isLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 60000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });

      const report = {
        fromDate: this.formattedFromDate,
        toDate: this.formattedToDate,
        filter: this.filter,
        sort: this.sort,
        sortOrder: this.sortDirection,
        supplierId: '',
        userId: this.loggedinUserId
      };

      console.log("Report:", report);

      http
        .post("/transaction/pdf", report, {
          responseType: "blob"
        })
        .then(response => {
          console.log(response);
          var newBlob = new Blob([response.data], {
            type: "application/octet-stream"
          });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              newBlob,
              "transactions-report.pdf"
            );
            return;
          }
          const data = window.URL.createObjectURL(newBlob);
          console.log(data);
          var link = document.createElement("a");
          link.style = "display: none;";
          link.href = data;
          link.download = "transaction-report.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.isLoading = false;
          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            this.isLoading = false;
          }, 100);
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    filterTableBySearch($event) {
      // Get typed in value in input
      const query = $event.target.value.trimStart().toLowerCase();
      // Get pressed key code
      const pressKeyCode = $event.keyCode;
      // Store length of query
      const queryLength = query != "" ? query.length : 0;
      if (queryLength > 0) {
        // Show cancel icon
        this.showDataList = true;
        // When the backspace key is pressed
        if (pressKeyCode == 8) {
          // Get value in input once again
          const value = $event.target.value.trimStart().toLowerCase();
          // Filter data
          this.selectedDistributor(value.toLowerCase());
        }
        // Filter clients data
        this.selectedDistributor(query.toLowerCase());
      } else {
        //  // Show cancel icon
        // this.showDataList = false;
        // Reset table data when there is no value in input
        this.resetTableData();
      }
    },
    filterTableData(query = "") {
      // Filter clients by search query
      const filterdSuppliers = this.masterData.filter(
        c =>
          c.supplierName.toLowerCase().includes(query.toLowerCase()) ||
          c.clientName.toLowerCase().includes(query.toLowerCase()) ||
          c.Id.toLowerCase().includes(query.toLowerCase())
      );
      // Store the filtered data as the new tatble data values
      this.tableData = filterdSuppliers;
    },
    resetTableData() {
      // Store clients from store and update table data
      this.formatTransactionsData(this.tableData);
      // Reset table data to show master data
      this.tableData = this.masterData;
    },
    formatTransactionsData(transactions) {
      // Convert data from observable to normal array
      const formattedTransactions = JSON.parse(JSON.stringify(transactions));
      // Key for getting unique values
      const key = "supplierName";
      // Create a set so that data only has unique values before we push it into dataList
      const uniqueTransactions = [
        ...new Map(
          formattedTransactions.map(item => [item[key], item])
        ).values()
      ];
      // Store the unique transactions in the dataList array
      this.dataList = JSON.parse(JSON.stringify(uniqueTransactions));
    },
    cancelFilterBySearch() {
      // Get the search input and set it to null/empty
      const input = (this.$refs.filterinput.$el.value = "");
      // Remove focused class
      this.isFocused = false;
      // Remove active class
      this.isActive = false;
      // Reset table data
      this.resetTableData();
    },
    removeCancelSearchIcon($event) {
      // Get typed in value in input
      const queryLength = $event.target.value.trimStart().length;
      // Remove cancel icons based on length value length
      if (queryLength == 0) {
        // Reverse is active
        this.isActive = false;
        // Reverse is focused
        this.isFocused = false;
      }
    },
    formatClientName(clientName) {
      return clientName.slice(0, 4).trimEnd() + "...";
    },
    removeReportPeriod(id) {
      // Convert string to JSON
      const formattedSuppliers = JSON.parse(JSON.stringify(this.clients));
      // Get index of selected time
      const filteredArray = formattedSuppliers.filter(
        p => p.supplierName !== supplierName
      );
      // Removed index array
      this.clients = filteredArray;
      // Remove id from report suppliers
      this.reportSupplierIds = this.reportSupplierIds.filter(i => i != id);
    },
    blur($event) {
      console.log($event);
      // Remove focused class on search
      this.isFocused = false;
      // Close data list dive
      this.showDataList = false;
    },
    focused() {
      // Set focused class on search input wrapper
      this.isFocused = true;
      // Open data list div
      this.showDataList = true;
    },
    /*
          Method modified
      */
    selectedDistributor(supplierNames) {
      // keep data list showing
      this.showDataList = true;
      // Empty text string
      this.filter = supplierNames.target.value.trimStart();
      // Store query value
      const query = supplierNames.target.value.trimStart();
      // Close the data list
      this.showDataList = false;
      // Remove focus class
      this.isFocused = false;
      // Filtered array
      const filteredArray = [];
      // Split entered values
      const suppliers = supplierNames.target.value.split(",");
      // Loop through names to filter table data
      for (var i = 0; i < suppliers.length; i++) {
        // filter term formatting
        const filter = suppliers[i].toLowerCase().trimStart();
        // Fitler table data here
        this.tableData = this.masterData
          .filter(
            p =>
              p.supplierName.toLowerCase().includes(filter) ||
              p.clientName.toLowerCase().includes(filter) ||
              p.Name.toLowerCase().includes(filter) ||
              p.Type.toLowerCase().includes(filter)
          )
          .concat(filteredArray);
      }

      console.log("Filter:", this.tableData);
    }
  },
  data() {
    return {
      filter: "",
      sort: "",
      sortDirection: "",
      supplierIDS: [],
      isActive: false,
      isFocused: false,
      showDataList: false,
      dataList: [],
      clients: [],
      disabled: true,
      reportSupplierIds: [],
      isModalVisible: false,
      transactionNumber: "-",
      currentPage: 1,
      pageSize: 10,
      transactionDetail: {},
      selectedId: "",
      selectedType: "",
      selectedSupplier: "",
      selectedClient: "",
      selectedAmount: 0,
      fromDate: DateTime.now().startOf('day').toJSDate(),
      toDate: DateTime.now().endOf('day').toJSDate(),
      supplierId: this.$route.query.supplierId || "",
      isLoading: false,
      isDetailLoading: true,
      suppliers: [
        {
          Name: "Show all",
          Id: ""
        }
      ],
      tableData: [],
      masterData: [],
      fields: [
        {
          key: "Amount",
          label: "value(R$)",
          sortable: true,
          formatter: (value, key, item) =>
            value.toLocaleString("en-ZA", { style: "currency", currency: "R" })
        }
      ]
    };
  },
  computed: {
    supplierIds() {
      const suppliers = this.$store.getters["accounts/user"].supplierIds;
      if (suppliers.length > 1) {
        this.supplierIDS = suppliers.split(",");
      }
      if (suppliers.length == 1){
        this.supplierIDS.push(suppliers);
      }
      return this.supplierIDS;
    },
    formattedFromDate: {
      get: function() {
        return this.formatDateISO(this.fromDate);
      },
      set: function(newValue) {
        this.fromDate = newValue;
      }
    },
    formattedToDate: {
      get: function() {
        return this.formatDateISO(this.toDate)
      },
      set: function(newValue) {
        this.toDate = newValue;
      }
    },
    rows() {
      return this.tableData.length;
    },
    displayData() {
      if (!this.tableData || this.tableData.length === 0) return [];
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
    transactionsTotal() {
      var total = 0;
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].Type == "Purchase") {
          total += parseFloat(this.tableData[i].Amount);
        } else {
          total -= parseFloat(this.tableData[i].Amount);
        }
      }
      return this.formatCurrency(total);
    },
    isSupplier() {
      return this.$store.getters["accounts/user"].supplierIds != ""
        ? true
        : false;
    },
    loggedinSupplierId() {
      return this.$store.getters["accounts/user"].supplierIds;
    },
    loggedinUserId() {
      return this.$store.getters["accounts/user"].Id;
    }
  },
  mounted() {
    this.isLoading = true;
    // Check if there are query passed to screen
    if (Object.keys(this.$route.query).length > 0) {
      console.log("Query:", this.$route.query);
      // Get query values
      const {
        filter,
        fromDate,
        supplierId,
        sort,
        sortOrder,
        toDate
      } = this.$route.query;
      // Set values
      this.fromDate = new Date(fromDate);
      this.toDate = new Date(toDate);
      this.filter = filter;
      this.sortOrder = sortOrder;
      this.sort = sort;
      this.supplierId = supplierId;
      console.log(this.supplierId);
    }

    var http = this.axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: this.$store.getters["accounts/token"] }
    });
    if (this.isSupplier) {
      http.get("/supplier/" + this.loggedinSupplierId).then(response => {
        this.suppliers = [response.data];
        this.supplierId = response.data.Id;
        http
          .post("/transaction/all", {
            fromDate: this.formatDateISO(this.fromDate),
            toDate: this.formatDateISO(this.toDate),
            filter: this.filter,
            sort: this.sort,
            sortOrder: this.sortDirection,
            userId: this.loggedinUserId
          })
          .then(response => {
            this.tableData = response.data;
            this.masterData = response.data;
            this.handleCurrentChange(1);
            this.isLoading = false;
          })
          .catch(err => {
            this.tableData = [];
            this.masterData = [];
            this.handleCurrentChange(1);
            this.isLoading = false;
          });
      });
    } else {
      // Get suppliers
      http.get("/supplier").then(response => {
        this.suppliers = response.data;
        this.suppliers.splice(0, 0, { Id: "", Name: "Show all" });
      });
      // Get transactions
      http
        .post("/transaction/all", {
          fromDate: this.formattedFromDate,
          toDate: this.formattedToDate,
          filter: this.filter,
          sort: this.sort,
          sortOrder: this.sortDirection,
          userId: this.loggedinUserId
        })
        .then(response => {
          this.tableData = response.data;
          this.masterData = response.data;
          this.handleCurrentChange(1);
          this.isLoading = false;
        })
        .catch(err => {
          this.tableData = [];
          this.masterData = [];
          this.handleCurrentChange(1);
          this.isLoading = false;
        });
    }
  }
};
</script>
<style>
.col-center {
  display: flex;
  justify-content: space-between;
}

.data-list input.focused {
  border-color: #f7941d !important;
}

.input-center {
  margin: 0 10px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
  margin: 0 !important;
}

.filter-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-size: 15px !important;
}

.data-list {
  position: relative;
}

.data-list .list {
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 999999;
  color: #606266;
  border: 1px solid #e4e7ed;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 4px;
  line-height: 30px;
  margin: 5px 0;
}

.data-list .list {
  list-style: none;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.data-list .list li {
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.data-list .list li:hover {
  background-color: #f5f5f5;
}

.selected-data {
  min-height: 20px;
}

.periods {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}

span.time {
  background: #f7941d;
  border-radius: 25px;
  font-size: 12px;
  color: #fff !important;
  position: relative;
  max-width: 60px;
  display: flex;
  align-items: center;
  padding: 4px;
  font-weight: 600;
  display: flex;
  min-height: 22px;
  min-width: 60px;
  margin-right: 10px;

  margin-bottom: 7px;
}

span.time .text {
  padding-left: 5px;
  line-height: 0 !important;
  display: inline-block;
  margin-bottom: -1px;
  padding-left: 5px;
  font-size: 11px;
  margin-left: -3px;
}

.time .remove {
  display: flex;
  border-radius: 50%;
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 4px;
  cursor: pointer;
  color: #f7941d;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
</style>
